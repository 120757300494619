import React from "react"
// import { Link } from "gatsby"
import { Row, Col } from "react-bootstrap"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import SubscribeForm from "../../components/subscribe-us"

import VideoPlayer from "../../components/youTube-Video"

import Arrow from "../../components/arrow"

const About = () => (
  <Layout>
    <SEO
      title={"Press"}
      titleDesc={"Fuseclassroom Press, AI news, Press conference"}
      titleKeywords={
        "fuse classroom,Best AI school, Admission in Machine Learning, AI micro masters program, AI courses, AI certification"
      }
      fbDes={"Fuseclassroom Press, AI news, Press conference"}
      twitDesc={"Fuseclassroom Press, AI news, Press conference"}
      seoLink={"press/"}
    />

    {/* top section */}
    <section role="top--section" className="press bg-green">
      <article className="container text-white">
        <Row className="align-items-center">
          <Col lg="5" md="5">
            <h1 className="text-white">Press</h1>
            <div className="h4 mb-4">Stay updated with latest news and reviews related to Fuse Classroom.</div>
            <SubscribeForm />
          </Col>
          <Col lg={{ span: 6, offset: 1 }} md="7">
            <div className="border-left border-light my-3">
              <div className="h6 px-4">
                NTV Discusses Fuse Classroom: <br />
                Using Artificial Intelligence to Transform Education in Nepal
              </div>
            </div>

            <div className="my-4">
              <div className="youtube-player">
                <VideoPlayer videoId="mXn1XWJFW1k" videoUrl="https://youtu.be/mXn1XWJFW1k?si=oxxehypwTFV8qVQj" />
              </div>
            </div>
          </Col>
        </Row>
      </article>
    </section>

    {/* news */}
    <section className="py-5">
      <article className="container">
        <Row>
          <Col md="4" className="my-4">
            <div className="card news">
              <div className="card-header">
                <img src="/images/press/ict-frame.webp" alt="News" />
              </div>
              <div className="card-body">
                <div className="mb-1">April 25, 2020</div>
                <h5 className="card-title">
                  Fusemachines Launches AI-Enabled Classroom | Will AI Shape the Education Sector?
                </h5>
                <p className="card-text">
                  Fusemachines introduces AI assisted learning by launching Fuse Classroom amid the COVID-19 crisis in
                  Nepal.
                </p>
              </div>
              <div className="card-footer">
                <a
                  href="https://ictframe.com/fusemachines-launches-ai-enabled-classroom-will-ai-shape-the-education-sector/?fbclid=IwAR2DMkbul_z-cxTSLoG3m0yibyp8wcrOwAtttTf5WAOfj8tbJeKMkipwRoo"
                  target="_blank"
                  className="text-success h5"
                >
                  READ MORE <Arrow />
                </a>
              </div>
            </div>
          </Col>

          <Col md="4" className="my-4">
            <div className="card news">
              <div className="card-header">
                <img src="/images/press/myrepublica.webp" alt="News" />
              </div>
              <div className="card-body">
                <div className="mb-1">April 24, 2020</div>
                <h5 className="card-title">Fusemachines launches Fuse Classroom, AI-enabled education platform</h5>
                <p className="card-text">
                  Fusemachines Inc. launches Fuse Classroom - an AI-enabled platform that will help bring AI assisted
                  learning in Nepal
                </p>
              </div>
              <div className="card-footer">
                <a
                  href="https://myrepublica.nagariknetwork.com/news/fusemachines-launches-fuse-classroom-ai-enabled-education-platform/?fbclid=IwAR2z0dDoeJQ64XESjpIav2GRF8RTl5mKLFloBy737lFI69hl3uLLHqUtIHM"
                  target="_blank"
                  className="text-success h5"
                >
                  READ MORE <Arrow />
                </a>
              </div>
            </div>
          </Col>

          <Col md="4" className="my-4">
            <div className="card news">
              <div className="card-header">
                <img src="/images/press/yahoo-finance.webp" alt="News" />
              </div>
              <div className="card-body">
                <div className="mb-1">April 23, 2020</div>
                <h5 className="card-title">Fusemachines Launches AI-Enabled Education Platform - Fuse Classroom</h5>
                <p className="card-text">
                  Find out how Fuse Classroom is poised to transform the overall school experience in many different
                  dimensions using AI.
                </p>
              </div>
              <div className="card-footer">
                <a
                  href="https://finance.yahoo.com/news/fusemachines-launches-ai-enabled-education-151800583.html"
                  target="_blank"
                  className="text-success h5"
                >
                  READ MORE <Arrow />
                </a>
              </div>
            </div>
          </Col>

          <Col md="4" className="my-4">
            <div className="card news">
              <div className="card-header">
                <img src="/images/press/ict-samachar.webp" alt="News" />
              </div>
              <div className="card-body">
                <div className="mb-1">जेठ २९, २०७७</div>
                <h5 className="card-title">
                  शिक्षा क्षेत्रलाई डिजिटल रूपान्तरणमा सघाउन ‘फ्युज क्लासरुम’ ल्याएका हौं - पराग श्रेष्ठ
                </h5>
                <p className="card-text">
                  नेपाली शिक्षा जगतमा डिजिटल रुपान्तरण ल्याउन फ्युज मेशिन्सले सुचारु गरेको फ्युज क्लासरुमको सन्दर्भमा
                  लिभिङ विथ आइसिटीसँग फ्युज क्लासरुमका निर्देशक पराग श्रेष्ठको विस्तृत अन्तर्वार्ता।
                </p>
              </div>
              <div className="card-footer">
                <a
                  href="https://ictsamachar.com/news-details/2693/2020-06-11?fbclid=IwAR3tvEjWzBswrQhopZGcgHNyQaZ2b6QPhO_XqBGydsQH2cE7k4dxux7sHF4"
                  target="_blank"
                  className="text-success h5"
                >
                  READ MORE <Arrow />
                </a>
              </div>
            </div>
          </Col>

          <Col md="4" className="my-4">
            <div className="card news">
              <div className="card-header">
                <img src="/images/press/techpana.webp" alt="News" />
              </div>
              <div className="card-body">
                <div className="mb-1">बैशाख १२, २०७७</div>
                <h5 className="card-title">अनलाइन कक्षा सञ्चालनमा सघाउने फ्युज मेसिनको एआई प्रविधि, यस्ता छन् फिचर</h5>
                <p className="card-text">
                  “कक्षा, कन्टेन्ट, प्रक्रिया, प्लेटफर्म र सम्पूर्ण स्कुल व्यवस्थापन सहितको सिंगो सोलुसन प्याकेज हो ,
                  फ्युज क्लासरुम। जान्नु होस् एस्का एआई समावेसित फिचरहरु यो लेखमा । “
                </p>
              </div>
              <div className="card-footer">
                <a href="https://www.techpana.com/2020/20814/" target="_blank" className="text-success h5">
                  READ MORE <Arrow />
                </a>
              </div>
            </div>
          </Col>

          <Col md="4" className="my-4">
            <div className="card news">
              <div className="card-header">
                <img src="/images/press/kantipur.webp" alt="News" />
              </div>
              <div className="card-body">
                <div className="mb-1">वैशाख १२, २०७७</div>
                <h5 className="card-title">फ्युजमेशिन्सले सुरु गर्‍यो डिजिटल क्लासरुम प्लेटफर्म</h5>
                <p className="card-text">
                  आर्टिफिसियल इन्टेलिजेन्स (एआई)क्षेत्रमा वर्षौं देखी आवद्द भएको फ्युजमेशिन्सले विद्यार्थी लक्षित
                  एआईसहितका फ्युज क्लासरुम नामक वेब तथा मोबाइल एप लर्निङ प्लेटफर्म सार्वजनिक गरेको छ ।{" "}
                </p>
              </div>
              <div className="card-footer">
                <a
                  href="https://ekantipur.com/technology/2020/04/24/1587735141367680.html?fbclid=IwAR3XRgf-YzuNFz2gwVBfnWP2UbHmlSnbYmDNDiYjrmQbNk1wUjS2d-bVpp0"
                  target="_blank"
                  className="text-success h5"
                >
                  READ MORE <Arrow />
                </a>
              </div>
            </div>
          </Col>

          <Col md="4" className="my-4">
            <div className="card news">
              <div className="card-header">
                <img src="/images/press/nagarik.webp" alt="News" />
              </div>
              <div className="card-body">
                <div className="mb-1">वैशाख १२, २०७७</div>
                <h5 className="card-title">फ्युजमेसिन्सद्वारा एआइयुक्त शैक्षिक प्ल्याटफर्म फ्युज क्लासरुम सुरु</h5>
                <p className="card-text">
                  फ्युजमेसिन्सद्वारा एआइयुक्त शैक्षिक प्ल्याटफर्म फ्युज क्लासरुमको सुरुवात । जान्नुहोस् यो प्ल्याटफर्मले
                  विद्यार्थीलाई कसरी रचनात्मक अनलाइन सिकाइको अनुभव दिने छ ।{" "}
                </p>
              </div>
              <div className="card-footer">
                <a
                  href="https://nagariknews.nagariknetwork.com/news/127323/"
                  target="_blank"
                  className="text-success h5"
                >
                  READ MORE <Arrow />
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </article>
    </section>

    {/* Contact for press inquiries */}
    <section>
      <article className="container border-top">
        <Row className="py-5 justify-content-center">
          <Col lg={12}>
            <h3 className="font-weight-semibold">Contact for press inquiries</h3>
          </Col>

          <Col xs={4} md={3}>
            <h5 className="">North America</h5>
            <h5 className="font-weight-semibold mb-0">Giovanni Lemus</h5>
            <a href="mailto:giovanni@fusemachines.com" className="h5 text-success font-weight-semibold">
              giovanni@fusemachines.com
            </a>
          </Col>

          <Col xs={4} md={3}>
            <h5 className="">Latin America</h5>
            <h5 className="font-weight-semibold mb-0">Onil Pereyra</h5>
            <a href="mailto:onil@fusemachines.com" className="h5 text-success font-weight-semibold">
              onil@fusemachines.com
            </a>
          </Col>

          <Col xs={4} md={3}>
            <h5 className="">South Asia</h5>
            <h5 className="font-weight-semibold mb-0">Parag Shrestha</h5>
            <a href="mailto:parag@fusemachines.com" className="h5 text-success font-weight-semibold">
              parag@fusemachines.com
            </a>
          </Col>

          <Col xs={4} md={3}>
            <h5 className="">Other Regions</h5>
            <a href="mailto:pr@fusemachines.com" className="h5 text-success font-weight-semibold">
              pr@fusemachines.com
            </a>
          </Col>
        </Row>
      </article>
    </section>

    {/* download */}
    <section className="bg-light-green py-4">
      <article className="container my-4">
        <Row className="">
          <Col lg="12">
            <h3 className="mb-4 font-weight-semibold">Brand Images</h3>
          </Col>

          <Col sm={3} xs={6}>
            <div className="text-black">
              <img src="/images/press/logo.svg" alt="logo" className="img-fluid" />
              <h6 className="mt-1">Logo</h6>
            </div>
          </Col>
          <Col sm={3} xs={6}>
            <div className="text-black">
              <img src="/images/press/product-image.webp" alt="product-images" className="img-fluid" />
              <h6 className="mt-1">Product Images</h6>
            </div>
          </Col>
          <Col sm={3} xs={6}>
            <div className="text-black">
              <img src="/images/press/office-images.webp" alt="office image" className="img-fluid" />
              <h6 className="mt-1">Office Images</h6>
            </div>
          </Col>
          <Col sm={3} xs={6}>
            <div className="text-black">
              <img src="/images/press/executive-bio.png" alt="executive bio" className="img-fluid" />
              <h6 className="mt-1">Executive Biography</h6>
            </div>
          </Col>

          <Col xs={12}>
            <a
              className="btn btn-success btn-lg mt-4 font-weight-semibold"
              target="_blank"
              href="https://drive.google.com/drive/folders/1QhQDWCpmU63Y5IHRWipl1ZrE6fojJrN8"
            >
              Download Press Kit
            </a>
          </Col>
        </Row>
      </article>
    </section>
    {/* End of top section */}
  </Layout>
)

export default About
