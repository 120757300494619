import React from "react"
import { Form, Field } from "react-final-form"
import { validateEmail } from "../utils"
import { subscribeUs } from "../actions"
import { HandleFBAdConversion } from "../utils/analytics"

class SubscribeForm extends React.Component {
  state = {
    showSuccessModal: false,
    disableSendBtn: false,
    errorSubmition: false,
  }

  validateFunc = values => {
    const errors = {}

    if (!values.email) {
      errors.email = "Please fill the above details"
    } else if (!validateEmail(values.email)) {
      errors.email = "Please enter in correct format"
    }

    return errors
  }

  onSubmitFunc = async (values, form, callback) => {
    if (!values.contactNumber) {
      values = {
        ...values,
        contactNumber: 0,
      }
    }

    // PartnerEventAnalytics("Click_Submit_Button")
    try {
      await subscribeUs(values)
      this.setState({ disableSendBtn: false })
      this.setState({ showSuccessModal: true }, () => {
        setTimeout(() => {
          HandleFBAdConversion()
          this.setState({ showSuccessModal: false })
        }, 3000)
      })
      setTimeout(() => {
        form.reset()
        callback()
      }, 3000)
    } catch (err) {
      this.setState({ disableSendBtn: false, errorSubmition: true })
    }
  }

  render() {
    const { showSuccessModal, disableSendBtn, errorSubmition } = this.state
    return (
      <>
        {!showSuccessModal ? (
          <Form
            onSubmit={this.onSubmitFunc}
            validate={this.validateFunc}
            render={({ handleSubmit, pristine, invalid, submitting, form, reset }) => (
              <form onSubmit={handleSubmit}>
                <Field name="email">
                  {({ input, meta }) => (
                    <div>
                      <div className="input-group input-group-lg">
                        <input
                          type="email"
                          {...input}
                          className="form-control"
                          placeholder="Enter your email address to subscribe"
                          required
                        />
                        <div className="input-group-append">
                          <button className="btn btn-warning" type="submit" disabled={submitting}>
                            SUBSCRIBE NOW
                          </button>
                        </div>
                      </div>
                      {meta.touched && meta.error && <div className="error">{meta.error}</div>}
                    </div>
                  )}
                </Field>
              </form>
            )}
          />
        ) : (
          <div className="h5">Thanks you for subscribing</div>
        )}
      </>
    )
  }
}

export default SubscribeForm
