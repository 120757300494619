import ReactGA from "react-ga"
import config from "../config"
ReactGA.initialize(config.googleAnalytics)

// export function FormEventAnalytics(buttonLabel) {
//   ReactGA.event({
//     category: "Request for more demo button click",
//     action: "Click",
//     label: buttonLabel,
//   })
// }

// export function PartnerEventAnalytics(label) {
//   ReactGA.event({
//     category: "FormEvent",
//     action: "Click",
//     // label,
//   })
// }

export function FBAdConversion() {
  ReactGA.event({
    category: "track",
    action: "Click",
    label: "PageView",
    value: 1,
  })
}

export const HandleFBAdConversion = () => {
  const utmSource = window.sessionStorage.utmSource

  if (utmSource && utmSource === "facebook") {
    FBAdConversion()
  }
}
